<template>
  <div v-if="!$apollo.loading">
    <b-row>
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>{{ $t('settings.plugins.pluginList.name') }}</h3>
        <hr>
        <p class="mb-5">{{ $t('settings.plugins.pluginList.desc') }}</p>
      </b-col>
    </b-row>
    <b-row class="mb-5" v-if="folderId !== null">
      <b-col cols="12" offset-sm="1" sm="10">
        <div class="override-children" :class="(toggleAllChildrenPluginOverride) ? 'expanded' : ''">
          <h4
            class="px-4 py-3 m-0 text-uppercase d-flex justify-content-between align-items-center"
          >
            <span>{{ $t('settings.folder.childrenOverride.label') }}</span>
            <b-form-checkbox
              id="toggle-all-children-plugin-override"
              v-model="toggleAllChildrenPluginOverride"
              name="toggle-all-children-plugin-override"
              size="lg"
              :value="true"
              :unchecked-value="false"
              @change="toggleOverrideAllChildrenBox($event)"
            >
            </b-form-checkbox>
          </h4>
          <b-collapse id="collapse-override-plugin-children">
            <div class="px-4 py-3">
              <p v-html="$t('settings.folder.childrenOverride.desc')"></p>
              <ul class="pl-0">
                <li>
                  <b-form-checkbox
                    id="override-all-children-plugins"
                    v-model="allChildrenPlugins"
                    name="override-all-children-plugins"
                    size="lg"
                    :value="true"
                    :unchecked-value="false"
                    @change="overrideAllChildren($event)"
                  >
                    {{ "All Plugins" }}
                  </b-form-checkbox>
                  <ul>
                    <li v-for="plugin in allChildrenPluginsArray" :key="plugin.handle">
                      <b-form-checkbox
                        v-if="plugin.handle === 'plugin_password_protection'"
                        :id="plugin.handle"
                        v-model="overrideChildrenPlugins[plugin.handle]"
                        :name="plugin.handle"
                        size="lg"
                        :disabled="true"
                        :value="true"
                        :unchecked-value="false"
                        @change="changeOverrideChildren(plugin.handle, $event)"
                      >
                        {{ $t(`settings.plugins.${plugin.name}.name`) }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-else
                        :id="plugin.handle"
                        v-model="overrideChildrenPlugins[plugin.handle]"
                        :name="plugin.handle"
                        size="lg"
                        :value="true"
                        :unchecked-value="false"
                        @change="changeOverrideChildren(plugin.handle, $event)"
                      >
                        {{ $t(`settings.plugins.${plugin.name}.name`) }}
                      </b-form-checkbox>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </b-collapse>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="showPluginSectionOther">
      <b-col cols="12" offset-sm="1" sm="10" class="mb-4">
        <h4>{{ $t('settings.plugins.pluginList.nameWebmag') }}</h4>
      </b-col>
    </b-row>
    <b-row v-if="showPluginSectionOther">
      <b-col cols="12" offset-sm="1" sm="10">
        <b-row>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_PASSWORD_PROTECTION'),
                isSysAdmin,
                privileges,
              )">
            <plugin-password-protection
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_PASSWORD_PROTECTION')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_password_protection"
              @update-plugin-data="updatePluginData($event, 'plugin_password_protection')"
            ></plugin-password-protection>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_EXIT_INTENT'),
                isSysAdmin,
                privileges,
              )">
            <plugin-exit-intent
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_EXIT_INTENT')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_exit_intent"
              @update-plugin-data="updatePluginData($event, 'plugin_exit_intent')"
            ></plugin-exit-intent>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_FLOATING_ICON_MENU'),
                isSysAdmin,
                privileges,
              )">
            <plugin-floating-icon-menu
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_FLOATING_ICON_MENU')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_floating_icon_menu"
              @update-plugin-data="updatePluginData($event, 'plugin_floating_icon_menu')"
            ></plugin-floating-icon-menu>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_INTRO_PLUGIN'),
                isSysAdmin,
                privileges,
              )">
            <plugin-intro
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_INTRO_PLUGIN')) || isSysAdmin"
              class="pb-4 mb-3"
              :plugin-data="pluginData.plugin_intro"
              @update-plugin-data="updatePluginData($event, 'plugin_intro')"
            ></plugin-intro>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_LEAD_MODAL'),
                isSysAdmin,
                privileges,
              )">
            <plugin-lead-modal
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_LEAD_MODAL')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_lead_modal"
              :child-page-slugs="childPageSlugs"
              @update-plugin-data="updatePluginData($event, 'plugin_lead_modal')"
            ></plugin-lead-modal>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_VARIABLES_PLUGIN'),
                isSysAdmin,
                privileges,
              )">
            <plugin-variables
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_VARIABLES_PLUGIN')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_variables"
              @update-plugin-data="updatePluginData($event, 'plugin_variables')"
            ></plugin-variables>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_GATED_CONTENT_HUBSPOT'),
                isSysAdmin,
                privileges,
              )">
            <plugin-gated-content
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_GATED_CONTENT_HUBSPOT')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_gated_content"
              @update-plugin-data="updatePluginData($event, 'plugin_gated_content')"
            ></plugin-gated-content>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_GATED_CONTENT_HUBSPOT'),
                isSysAdmin,
                privileges,
              )">
            <plugin-gated-content-hubspot
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_GATED_CONTENT_HUBSPOT')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_gated_content_hubspot"
              @update-plugin-data="updatePluginData($event, 'plugin_gated_content_hubspot')"
            ></plugin-gated-content-hubspot>
          </b-col>

          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_EMBED_PLUGIN'),
                isSysAdmin,
                privileges,
              )">
            <plugin-embed
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_EMBED_PLUGIN')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_embed"
              @update-plugin-data="updatePluginData($event, 'plugin_embed')"
            ></plugin-embed>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="showPluginSectionAnalyticsAndTracking">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4 class="mb-4 mt-4">{{ $t('settings.plugins.pluginList.nameTrackingSection') }}</h4>
      </b-col>
    </b-row>
    <b-row v-if="showPluginSectionAnalyticsAndTracking">
      <b-col cols="12" offset-sm="1" sm="10">
        <b-row>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_GOOGLE_ANALYTICS'),
                isSysAdmin,
                privileges,
              )">
            <plugin-google-analytics
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_GOOGLE_ANALYTICS')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_google_analytics"
              @update-plugin-data="updatePluginData($event, 'plugin_google_analytics')"
            ></plugin-google-analytics>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_MATOMO_ANALYTICS'),
                isSysAdmin,
                privileges,
              )">
            <plugin-matomo-analytics
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_MATOMO_ANALYTICS')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_matomo_analytics"
              @update-plugin-data="updatePluginData($event, 'plugin_matomo_analytics')"
            ></plugin-matomo-analytics>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_GOOGLE_TAG_MANAGER'),
                isSysAdmin,
                privileges,
              )">
            <plugin-google-tag-manager
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_GOOGLE_TAG_MANAGER')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_google_tag_manager"
              @update-plugin-data="updatePluginData($event, 'plugin_google_tag_manager')"
            ></plugin-google-tag-manager>
          </b-col>

          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_GOOGLE_TAG_MANAGER_TEF'),
                isSysAdmin,
                privileges,
              )">
            <plugin-google-tag-manager-tef
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_GOOGLE_TAG_MANAGER_TEF')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_google_tag_manager_tef"
              @update-plugin-data="updatePluginData($event, 'plugin_google_tag_manager_tef')"
            ></plugin-google-tag-manager-tef>
          </b-col>

          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_PLAUSIBLE'),
                isSysAdmin,
                privileges,
              )">
            <plugin-plausible
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_PLAUSIBLE')) || isSysAdmin"
              class="pb-4 mb-3"
              :plugin-data="pluginData.plugin_plausible"
              @update-plugin-data="updatePluginData($event, 'plugin_plausible')"
            ></plugin-plausible>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_FACEBOOK_PIXEL'),
                isSysAdmin,
                privileges,
              )">
            <plugin-facebook-pixel
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_FACEBOOK_PIXEL')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_facebook_pixel"
              @update-plugin-data="updatePluginData($event, 'plugin_facebook_pixel')"
            ></plugin-facebook-pixel>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_LINKEDIN_PIXEL'),
                isSysAdmin,
                privileges,
              )">
            <plugin-linkedin-pixel
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_LINKEDIN_PIXEL')) || isSysAdmin"
              class="pb-4 mb-3"
              :plugin-data="pluginData.plugin_linkedin_pixel"
              @update-plugin-data="updatePluginData($event, 'plugin_linkedin_pixel')"
            ></plugin-linkedin-pixel>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_FEEDBUCKET'),
                isSysAdmin,
                privileges,
              )">
            <plugin-feedbucket
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_FEEDBUCKET')) || isSysAdmin"
              class="pb-4 mb-3"
              :plugin-data="pluginData.plugin_feedbucket"
              @update-plugin-data="updatePluginData($event, 'plugin_feedbucket')"
            ></plugin-feedbucket>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="showPluginSectionDataPrivacy">
      <b-col cols="12" offset-sm="1" sm="10" class="mb-4 mt-4">
        <h4>{{ $t('settings.plugins.pluginList.nameDataPolicy') }}</h4>
      </b-col>
    </b-row>
    <b-row v-if="showPluginSectionDataPrivacy">
      <b-col cols="12" offset-sm="1" sm="10">
        <b-row>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_COOKIES_CONSENT'),
                isSysAdmin,
                privileges,
              )">
            <plugin-cookies-consent
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_COOKIES_CONSENT')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_cookies_consent"
              @update-plugin-data="updatePluginData($event, 'plugin_cookies_consent')"
            ></plugin-cookies-consent>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_COOKIEBOT'),
                isSysAdmin,
                privileges,
              )">
            <plugin-cookie-bot
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_COOKIEBOT')) || isSysAdmin"
              class="pb-4 mb-3"
              :plugin-data="pluginData.plugin_cookie_bot"
              @update-plugin-data="updatePluginData($event, 'plugin_cookie_bot')"
            ></plugin-cookie-bot>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_CCM19'),
                isSysAdmin,
                privileges,
              )">
            <plugin-ccm19
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_CCM19')) || isSysAdmin"
              class="pb-4 mb-3"
              :plugin-data="pluginData.plugin_ccm19"
              @update-plugin-data="updatePluginData($event, 'plugin_ccm19')"
            ></plugin-ccm19>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_USERCENTRICS'),
                isSysAdmin,
                privileges,
              )">
            <plugin-usercentrics
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_USERCENTRICS')) || isSysAdmin"
              class="pb-4 mb-3"
              :plugin-data="pluginData.plugin_usercentrics"
              @update-plugin-data="updatePluginData($event, 'plugin_usercentrics')"
            ></plugin-usercentrics>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="showPluginSectionOther">
      <b-col cols="12" offset-sm="1" sm="10" class="mb-4 mt-4">
        <h4>{{ $t('settings.plugins.pluginList.nameOthers') }}</h4>
      </b-col>
    </b-row>
    <b-row v-if="showPluginSectionOther">
      <b-col cols="12" offset-sm="1" sm="10">
        <b-row>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_DISQUS'),
                isSysAdmin,
                privileges,
              )">
            <plugin-disqus
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_DISQUS')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_disqus"
              @update-plugin-data="updatePluginData($event, 'plugin_disqus')"
            ></plugin-disqus>
          </b-col>

          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_123_CHAT'),
                isSysAdmin,
                privileges,
              )">
            <plugin-one-two-three-chat
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_123_CHAT')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_123_chat"
              @update-plugin-data="updatePluginData($event, 'plugin_123_chat')"
            ></plugin-one-two-three-chat>
          </b-col>

          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_WEGLOT'),
                isSysAdmin,
                privileges,
              )">
            <plugin-weglot
              :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_WEGLOT')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_weglot"
              @update-plugin-data="updatePluginData($event, 'plugin_weglot')"
            ></plugin-weglot>
          </b-col>
          <b-col cols="12" sm="4" v-if="$hasPrivileges(
                $privileges('CAN_USE_CHATGPT'),
                isSysAdmin,
                privileges,
              )">
            <plugin-chatGPT
            :edit-privilege="privileges.includes(
                $privileges('CAN_EDIT_CHATGPT')) || isSysAdmin"
              class="pb-3"
              :plugin-data="pluginData.plugin_chatGPT"
              @update-plugin-data="updatePluginData($event, 'plugin_chatGPT')"
            ></plugin-chatGPT>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import GetAllPluginDataOfFolder from '@/graphQlQueries/queries/getAllPluginDataOfFolder';
import GetAllPluginDataOfProject from '@/graphQlQueries/queries/getAllPluginDataOfProject';

import PluginGoogleTagManager from '@/components/settings/plugins/PluginGoogleTagManager.vue';
import PluginGoogleTagManagerTef from '@/components/settings/plugins/PluginGoogleTagManagerTef.vue';
import PluginGoogleAnalytics from '@/components/settings/plugins/PluginGoogleAnalytics.vue';
import PluginMatomoAnalytics from '@/components/settings/plugins/PluginMatomoAnalytics.vue';
import PluginPasswordProtection from '@/components/settings/plugins/PluginPasswordProtection.vue';
import PluginCookiesConsent from '@/components/settings/plugins/PluginCookiesConsent.vue';
import PluginFacebookPixel from '@/components/settings/plugins/PluginFacebookPixel.vue';
import PluginLinkedinPixel from '@/components/settings/plugins/PluginLinkedinPixel.vue';
import PluginFeedbucket from '@/components/settings/plugins/PluginFeedbucket.vue';
import PluginUsercentrics from '@/components/settings/plugins/PluginUsercentrics.vue';
import PluginPlausible from '@/components/settings/plugins/PluginPlausible.vue';
import PluginExitIntent from '@/components/settings/plugins/PluginExitIntent.vue';
import PluginGatedContent from '@/components/settings/plugins/PluginGatedContent.vue';
import PluginDisqus from '@/components/settings/plugins/PluginDisqus.vue';
import PluginFloatingIconMenu from '@/components/settings/plugins/PluginFloatingIconMenu.vue';
import PluginCookieBot from '@/components/settings/plugins/PluginCookieBot.vue';
import PluginCcm19 from '@/components/settings/plugins/PluginCcm19.vue';
import PluginIntro from '@/components/settings/plugins/PluginIntro.vue';
import PluginOneTwoThreeChat from '@/components/settings/plugins/Plugin123Chat.vue';
import PluginWeglot from '@/components/settings/plugins/PluginWeglot.vue';
import PluginVariables from '@/components/settings/plugins/PluginVariables.vue';
import PluginChatGPT from '@/components/settings/plugins/PluginChatGPT.vue';
import PluginLeadModal from '@/components/settings/plugins/PluginLeadModal.vue';
import PluginGatedContentHubspot from '@/components/settings/plugins/PluginGatedContentHubspot.vue';
import PluginEmbed from '@/components/settings/plugins/PluginEmbed.vue';

export default {
  name: 'PluginList',
  components: {
    PluginFacebookPixel,
    PluginLinkedinPixel,
    PluginFeedbucket,
    PluginUsercentrics,
    PluginPlausible,
    PluginGoogleTagManager,
    PluginGoogleTagManagerTef,
    PluginGoogleAnalytics,
    PluginMatomoAnalytics,
    PluginPasswordProtection,
    PluginExitIntent,
    PluginGatedContent,
    PluginGatedContentHubspot,
    PluginDisqus,
    PluginFloatingIconMenu,
    PluginCookiesConsent,
    PluginCookieBot,
    PluginCcm19,
    PluginOneTwoThreeChat,
    PluginWeglot,
    PluginVariables,
    PluginIntro,
    PluginChatGPT,
    PluginLeadModal,
    PluginEmbed,
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    folderId: {
      type: Number,
      default: null,
    },
    privileges: {
      type: Array,
    },
    isSysAdmin: {
      type: Boolean,
      default: false,
    },
    childPageSlugs: {
      type: Array,
    },
  },
  data: () => ({
    toggleAllChildrenPluginOverride: false,
    overrideChildren: false,
    allChildrenPlugins: false,
    overrideChildrenPlugins: {
      plugin_google_analytics: false,
      plugin_matomo_analytics: false,
      plugin_google_tag_manager: false,
      plugin_google_tag_manager_tef: false,
      plugin_plausible: false,
      plugin_facebook_pixel: false,
      plugin_linkedin_pixel: false,
      plugin_feedbucket: false,
      plugin_cookies_consent: false,
      plugin_cookie_bot: false,
      plugin_ccm19: false,
      plugin_usercentrics: false,
      plugin_password_protection: false,
      plugin_exit_intent: false,
      plugin_floating_icon_menu: false,
      plugin_gated_content: false,
      plugin_gated_content_hubspot: false,
      plugin_chatGPT: false,
      plugin_disqus: false,
      plugin_123_chat: false,
      plugin_weglot: false,
      plugin_variables: false,
      plugin_intro: false,
      plugin_embed: false,
    },
    pluginData: null,
    updatedPlugins: {},
  }),
  apollo: {
    pluginData: {
      query() {
        return (this.projectId !== null)
          ? GetAllPluginDataOfProject
          : GetAllPluginDataOfFolder;
      },
      variables() {
        return (this.projectId !== null)
          ? { projectId: this.projectId }
          : { folderId: this.folderId };
      },
      update(data) {
        const pluginDataOfDb = {};
        data.plugins.forEach((dataItem) => {
          pluginDataOfDb[dataItem.handle] = {
            enabled: dataItem.enabled,
            settings: dataItem.settings,
          };
        });
        return pluginDataOfDb;
      },
      fetchPolicy: 'network-only',
    },
  },
  computed: {
    allChildrenPluginsArray() {
      return Object.entries(this.overrideChildrenPlugins).map(([handle]) => {
        const nameString = handle.replace(/(^plugin_)/gi, '');
        return {
          name: nameString.replace(/_([a-z]|\d)/g, (g) => g[1].toUpperCase()),
          handle,
        };
      });
    },
    showPluginSectionAnalyticsAndTracking() {
      const analyticsAndTrackingPrivileges = [
        this.$privileges('CAN_USE_GOOGLE_ANALYTICS'),
        this.$privileges('CAN_USE_MATOMO_ANALYTICS'),
        this.$privileges('CAN_USE_GOOGLE_TAG_MANAGER'),
        this.$privileges('CAN_USE_GOOGLE_TAG_MANAGER_TEF'),
        this.$privileges('CAN_USE_USERCENTRICS'),
        this.$privileges('CAN_USE_PLAUSIBLE'),
        this.$privileges('CAN_USE_FACEBOOK_PIXEL'),
        this.$privileges('CAN_USE_LINKEDIN_PIXEL'),
        this.$privileges('CAN_USE_FEEDBUCKET'),
      ];
      return this.checkForThePrivileges(analyticsAndTrackingPrivileges);
    },
    showPluginSectionDataPrivacy() {
      const dataPrivacyPrivileges = [
        this.$privileges('CAN_USE_COOKIES_CONSENT'),
        this.$privileges('CAN_USE_COOKIEBOT'),
        this.$privileges('CAN_USE_CCM19'),
      ];
      return this.checkForThePrivileges(dataPrivacyPrivileges);
    },
    showPluginSectionOther() {
      const otherPrivileges = [
        this.$privileges('CAN_USE_PASSWORD_PROTECTION'),
        this.$privileges('CAN_USE_EXIT_INTENT'),
        this.$privileges('CAN_USE_FLOATING_ICON_MENU'),
        this.$privileges('CAN_USE_GATED_CONTENT_HUBSPOT'),
        this.$privileges('CAN_USE_DISQUS'),
        this.$privileges('CAN_USE_123_CHAT'),
        this.$privileges('CAN_USE_WEGLOT'),
        this.$privileges('CAN_USE_VARIABLES_PLUGIN'),
        this.$privileges('CAN_USE_INTRO_PLUGIN'),
        this.$privileges('CAN_USE_CHATGPT'),
        this.$privileges('CAN_USE_LEAD_MODAL'),
        this.$privileges('CAN_USE_EMBED_PLUGIN'),
      ];
      return this.checkForThePrivileges(otherPrivileges);
    },
  },
  methods: {
    toggleOverrideAllChildrenBox(value) {
      this.$root.$emit('bv::toggle::collapse', 'collapse-override-plugin-children');
      if (!value) {
        Object.entries(this.overrideChildrenPlugins).forEach(([key]) => {
          this.overrideChildrenPlugins[key] = false;
        });
        this.allChildrenPlugins = false;
        this.$emit('override-all-children', false);
      }
    },
    overrideAllChildren(value) {
      if (value) {
        Object.entries(this.overrideChildrenPlugins).forEach(([key]) => {
          if (key !== 'plugin_password_protection') {
            this.overrideChildrenPlugins[key] = true;
          }
        });
      } else {
        Object.entries(this.overrideChildrenPlugins).forEach(([key]) => {
          this.overrideChildrenPlugins[key] = false;
        });
      }
      this.$emit('override-all-children', value);
    },
    changeOverrideChildren(name, value) {
      this.$emit('override-children', name, value);
    },
    checkForThePrivileges(arrayOfRights) {
      if (this.isSysAdmin) return true;

      let hasRightToSeeSection = false;
      arrayOfRights.some((privilege) => {
        if (this.privileges.includes(privilege)) {
          hasRightToSeeSection = true;
          return true;
        }
        return false;
      });
      return hasRightToSeeSection;
    },
    updatePluginData(pluginData, pluginName) {
      this.updatedPlugins[pluginName] = pluginData;
      this.updatedPlugins[pluginName].dataFromDb = !!this.pluginData[pluginData.handle];

      this.$emit('update-plugin-settings', this.updatedPlugins);
    },
  },
};
</script>

<style scoped lang="scss">
.override-children {
  border: 1px solid #bbb;
  h4 {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  &.expanded {
    border: 1px solid #A05B5B;
    h4 {
      background-color: #A05B5B;
      color: #fff;
    }
  }

  ul {
    list-style: none;
  }
}
hr {
  border-top-color: #bbb;
  border-style: dotted;
}

h4 {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}

/deep/ input[disabled="disabled"] ~ label {
  cursor: not-allowed;
}
</style>
