<template>
  <div>
    <div id="editor"></div>
  </div>
</template>

<script>
import CodeFlask from 'codeflask';

export default {
  name: 'CustomJsEditor',
  props: {
    jsCode: {
      type: String,
      default: null,
    },
  },
  mounted() {
    const flask = new CodeFlask('#editor', {
      language: 'js',
      lineNumbers: true,
    });
    flask.updateCode(this.jsCode || '');
    flask.onUpdate((code) => {
      this.$emit('update-code', code);
    });
  },
};
</script>

<style scoped lang="scss">
#editor {
  position: relative;
    width: 100%;
    height: 336px;
    margin-bottom: 15px;
  /deep/ .codeflask {
    width: calc(100% - 40px);
    &::before, .codeflask__lines {
      background: #FFF;
      width: 40px;
      z-index: 9;
    }
    .codeflask__textarea {
      background-color: #fff;
      width: calc(100% - 40px);
      border: 1px solid grey;
    }
    .codeflask__code {
      overflow: hidden;
    }
  }
}
</style>
